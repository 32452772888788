import NavbarComponent from '../components/navbar.component'
import BannerEnum from '../enumerators/banner.enum'

export default class HeaderService {
    constructor() {
        new NavbarComponent()

        // Banner
        if ($(BannerEnum.VIDEO).length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
            import(
                '@scripts/components/banner-video.component' /* webpackChunkName: "scripts/banner-video.component" */
            ).then(({ default: BannerVideoComponent }) => {
                new BannerVideoComponent()
            })
        } else if ($(BannerEnum.SLIDER).length > 0) {
            import(
                '@scripts/components/banner-slider.component' /* webpackChunkName: "scripts/banner-slider.component" */
            ).then(({ default: BannerSliderComponent }) => {
                new BannerSliderComponent()
            })
        }
    }
}
