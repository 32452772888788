import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.searchFocus()
        NavbarComponent.langsSwitcher()

        if (window.matchMedia('(min-width: 1201px)').matches) {
            NavbarComponent.changeNavbar()
        }
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('has-submenu-open')
            } else {
                $(NavbarEnum.BODY).addClass('menu-open')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }
        })

        // Close on escape key
        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('has-submenu-open')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
            $(NavbarEnum.BODY).toggleClass('has-submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
        })

        $(NavbarEnum.BURGER_SUBMENU_CLOSE).on('click', () => {
            $(NavbarEnum.BODY).removeClass('menu-open')
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
        })

        // Collapse mobile
        if (window.matchMedia('(max-width: 1201px)').matches) {
            $(NavbarEnum.BURGER_COLLAPSE).on('click', function (e) {
                e.preventDefault()
                $(this).toggleClass('open')
                $(this).next(NavbarEnum.BURGER_LEVEL_3_ITEMS).toggleClass('open')
            })
        }
    }

    static searchFocus() {
        if ($(NavbarEnum.SEARCH_MODAL_INPUT).length > 0) {
            $(NavbarEnum.SEARCH_TRIGGER).on('click', () => {
                document.addEventListener('show.hc.modal', () => {
                    $(NavbarEnum.SEARCH_MODAL_INPUT).focus()
                })
            })
        }
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //Open List
        $(current_lang).on('click', () => {
            $(NavbarEnum.LIST_LANG).toggleClass('tools__item__languages__langs--open')

            //Hide list lang on outside click
            if ($(NavbarEnum.LIST_LANG).hasClass('tools__item__languages__langs--open')) {
                $(window).click((e) => {
                    if (
                        e.target.getAttribute('data-id') !== 'current-lang' &&
                        e.target.className !== 'current__lang'
                    ) {
                        $(NavbarEnum.LIST_LANG).removeClass('tools__item__languages__langs--open')
                    }
                })
            }
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.tools__item__languages__current span').html(currentLangCode.substr(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.tools__item__languages__current span').html(currentLangCode.substr(0, 2))
        }, '1000')
    }

    static changeNavbar() {
        const menu = NavbarEnum.MENU
        const tools = NavbarEnum.TOOLS

        if ($(menu).length > 0 || $(tools).length > 0) {
            $(menu)
                .add(tools)
                .on('mouseover', () => {
                    $(NavbarEnum.NAVBAR).addClass('navbar--active')
                    $(NavbarEnum.BODY).addClass('change-navbar-background')
                })
            $(menu)
                .add(tools)
                .on('mouseout', () => {
                    $(NavbarEnum.NAVBAR).removeClass('navbar--active')
                    $(NavbarEnum.BODY).removeClass('change-navbar-background')
                })
        }
    }
}
